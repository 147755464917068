@import url(https://fonts.googleapis.com/css?family=Baloo|Muli:300,400,700,900&display=swap);
@media only screen and (max-width: 600px) {
  .trip-container {
    background-color: #c3d8e7;
    padding-bottom: 3rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-height: 100vh;
  }

  .trip-details-section {
    background-image: url(https://source.unsplash.com/random);
    background-size: cover;
  }

  .trip-details-title {
    font-size: 2.5rem;
  }

  .trip-destination-title {
    font-size: 2.85rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 16%;
  }

  .trip-destination-time-space {
    width: 28.3%;
  }

  .trip-details-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding-bottom: 1.5rem;
  }

  .trip-itinerary-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(10% / 2);
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #4996c9;
    border-radius: 2.5rem;
    padding-bottom: 3rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .trip-details-section-background-gradient {
    background: rgb(73, 150, 201);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(73, 150, 201, 0.5)),
      color-stop(50%, rgba(73, 150, 201, 0.5)),
      to(rgba(73, 150, 201, 0.5))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
  }

  .trip-destination-dates {
    font-size: 1.75rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .trip-container {
    background-color: #c3d8e7;
    padding-bottom: 3rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-height: 100vh;
  }

  .trip-details-section {
    background-image: url(https://source.unsplash.com/random);
    background-size: cover;
  }

  .trip-details-title {
    font-size: 2.5rem;
  }

  .trip-destination-title {
    font-size: 2.85rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    width: 16%;
  }

  .trip-destination-time-space {
    width: 28.3%;
  }

  .trip-details-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding-bottom: 1.5rem;
  }

  .trip-itinerary-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(10% / 2);
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #4996c9;
    border-radius: 2.5rem;
    padding-bottom: 3rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .trip-details-section-background-gradient {
    background: rgb(73, 150, 201);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(73, 150, 201, 0.5)),
      color-stop(50%, rgba(73, 150, 201, 0.5)),
      to(rgba(73, 150, 201, 0.5))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
  }

  .trip-destination-dates {
    font-size: 1.75rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    width: 45%;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .trip-container {
    background-color: #c3d8e7;
    padding-bottom: 3rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-height: 100vh;
  }

  .pack-button-container {
    width: 16%;
  }

  .pack-button {
    display: flex;
    flex-wrap: wrap;
  }

  .trip-details-title {
    font-size: 3.5rem;
  }

  .trip-destination-title {
    font-size: 4.85rem;
  }

  .trip-destination-dates {
    font-size: 2.5rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    width: 45%;
    font-size: 2.5rem;
  }

  .trip-destination-time-space {
    width: 28.3%;
  }

  .trip-details-section {
  }
  .trip-details-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  .trip-itinerary-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(10% / 2);
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #4996c9;
    border-radius: 2.5rem;
    padding-bottom: 3rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .trip-details-section-background-gradient {
    background: rgb(73, 150, 201);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(73, 150, 201, 0.5)),
      color-stop(50%, rgba(73, 150, 201, 0.5)),
      to(rgba(73, 150, 201, 0.5))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
  }
}
@media only screen and (min-width: 992px) {
  .trip-container {
    background-color: #c3d8e7;
    padding-bottom: 3rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-height: 100vh;
  }

  .pack-button-container {
    width: 16%;
  }

  .pack-button {
    display: flex;
    flex-wrap: wrap;
  }

  .trip-details-title {
    font-size: 3.5rem;
  }

  .trip-destination-title {
    font-size: 4.85rem;
  }

  .trip-destination-dates {
    font-size: 2.5rem;
  }

  .trip-departure-time-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
    font-size: 2.5rem;
  }

  .trip-destination-time-space {
    width: 28.3%;
  }

  .trip-details-section {
  }
  .trip-details-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  .trip-itinerary-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(10% / 2);
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #4996c9;
    border-radius: 2.5rem;
    padding-bottom: 3rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .trip-details-section-background-gradient {
    background: rgb(73, 150, 201);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(73, 150, 201, 0.5)),
      color-stop(50%, rgba(73, 150, 201, 0.5)),
      to(rgba(73, 150, 201, 0.5))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(73, 150, 201, 0.5) 0%,
      rgba(73, 150, 201, 0.5) 50%,
      rgba(73, 150, 201, 0.5) 100%
    );
  }
}

@media only screen and (max-width: 600px) {
  .itinerary-card-body {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2rem;
  }

  .itinerary-card-text {
    font-size: 1.5rem;
  }

  .itinerary-category-card-header {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media only screen and (max-width: 768px) {
  .itinerary-card-body {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2rem;
  }

  .itinerary-card-text {
    font-size: 1.5rem;
  }

  .itinerary-category-card-header {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media only screen and (min-width: 768px) {
  .itinerary-card-body {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2rem;
  }

  .itinerary-card-text {
    font-size: 1.5rem;
  }

  .itinerary-category-card-header {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .itinerary-card-header-img {
    width: 20%;
    height: 80%;
  }
}

@media only screen and (min-width: 992px) {
  .itinerary-card-body {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2rem;
  }

  .itinerary-card-text {
    font-size: 1.5rem;
  }

  .itinerary-category-card-header {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .itinerary-card-header-img {
    width: 20%;
    height: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .add-itinerary-form-container {
    margin-top: 2.5rem;
    margin-left: 0.25rem;
    padding: 2rem;
    width: 100%;
  }

  .add-itinerary-form-label {
    font-size: 1.5rem;
  }

  .add-itinerary-form-input {
    font-size: 1.5rem;
  }

  .add-itinerary-form-type-selector {
    border: 1px solid grey;
    padding: 0.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .add-itinerary-form-container {
    margin-top: 2.5rem;
    padding: 2rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .add-itinerary-form-label {
    font-size: 1.5rem;
  }

  .add-itinerary-form-input {
    font-size: 1.5rem;
  }

  .add-itinerary-form-type-selector {
    border: 1px solid grey;
    padding: 0.5rem;
    font-size: 2.5rem !important;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (max-width: 600px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 100%;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
  }
}

@media only screen and (max-width: 768px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 100%;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
  }
}

@media only screen and (min-width: 768px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-title {
    font-size: 3.25rem;
  }

  .itinerary-container {
    padding: 1rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
    font-size: 1.5rem !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-title {
    font-size: 3.25rem;
  }

  .itinerary-container {
    padding: 1rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
    font-size: 1.5rem !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .weather-card {
    font-size: 1rem;
    width: 18%;
    padding: 0;
    background-color: #eef0f2;
    color: #4996c9;
  }

  .weather-icon {
    width: 100%;
    padding: 0;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .weather-card-title-text {
    font-size: 1.65rem;
  }

  .weather-card-weather-text {
    font-size: 1.115rem;
  }

  .weather-card-label-text {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 768px) {
  .weather-card {
    font-size: 1rem;
    width: 18%;
    padding: 0;
    background-color: #eef0f2;
    color: #4996c9;
  }

  .weather-icon {
    width: 100%;
    padding: 0;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .weather-card-title-text {
    font-size: 1.65rem;
  }

  .weather-card-weather-text {
    font-size: 1.115rem;
  }

  .weather-card-label-text {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 768px) {
  .weather-card {
    font-size: 10px;
    width: 18%;
    padding: 0;
    background-color: #eef0f2;
    color: #4996c9;
  }

  .weather-icon {
    width: 100%;
    padding: 0;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .weather-card-title-text {
    font-size: 2.8rem;
  }

  .weather-card-weather-text {
    font-size: 1.75rem;
  }

  .weather-card-label-text {
    font-size: 1.35rem;
  }

  .weather-card-temp-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 992px) {
  .weather-card {
    font-size: 10px;
    width: 18%;
    padding: 0;
    background-color: #eef0f2;
    color: #4996c9;
  }

  .weather-icon {
    width: 100%;
    padding: 0;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .weather-card-title-text {
    font-size: 2.8rem;
  }

  .weather-card-weather-text {
    font-size: 1.75rem;
  }

  .weather-card-label-text {
    font-size: 1.35rem;
  }

  .weather-card-temp-container {
    display: flex;
    flex-wrap: wrap;
  }
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--white);
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--white);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 500px;
  width: 500px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--bundleBlue)), to(var(--babyBlue)));
  background: -webkit-linear-gradient(top, var(--bundleBlue), var(--babyBlue));
  background: linear-gradient(180deg, var(--bundleBlue), var(--babyBlue));
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--white);
  padding: 0.4em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
  color: white;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.bm-item:hover {
  color: var(--denimBlue);
  text-decoration: none;
}

/* Styling of overlay */
.bm-overlay {
  background: var(--bundleBlue70);
}

.logout-Button {
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.logout-Button:hover {
  color: var(--white);
  background: var(--bundleBlue70);
}

.createTripform-button-disabled {
  cursor: not-allowed;
}

.createTripform-button {
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.createTripform-button:hover {
  background: #1e3799;
}

.createTrip-icon {
  font-size: 1.5rem;
  color: var(--smokeblack);
  padding-left: 1.5rem;
}

.createTripform-input {
  font-size: 1.9rem;
  outline-color: var(--bundleBlue);
  color: var(--smokeblack);
  border-radius: 0;
}

.createTripform-input::-webkit-input-placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input::-moz-placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input:-ms-input-placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input::-ms-input-placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input::placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--smokeblack);
}

.createTripform-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--smokeblack);
}

.createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover,
.createTripform-input:-webkit-autofill:focus
  .createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover
  .createTripform-input:-webkit-autofill:focus,
.createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover,
.createTripform-input:-webkit-autofill:focus {
  -webkit-box-shadow: transparent;
  -webkit-text-fill-color: var(--smokeblack);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.ap-input {
  height: 45px;
}

.ais-InstantSearch__root {
  width: 100%;
}

.placeholder-destination::-webkit-input-placeholder {
  color: grey;
}

.placeholder-destination::-moz-placeholder {
  color: grey;
}

.placeholder-destination:-ms-input-placeholder {
  color: grey;
}

.placeholder-destination::-ms-input-placeholder {
  color: grey;
}

.placeholder-destination::placeholder {
  color: grey;
}

.displayNone {
  display: none;
}

.displayModal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.714);
  z-index: 1000;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

.displayModalMain {
  position: fixed;
  background: #e6e6e6;
  width: 95%;
  height: 95%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modalCloseButton {
  position: absolute;
  top: 0;
  right: 30px;
  margin: 10px;
  background: none;
  border: none;
  font-size: 50px;
}

.modalContent {
  margin: 20px;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

.flipOutY {
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
          animation-name: flipOutY;
}

.suggestions-categories {
  display: flex;
  overflow-x: scroll;
}

.inactiveCategory-item {
  -webkit-transition: border, border-radius, color 0.2s ease;
  transition: border, border-radius, color 0.2s ease;
}

.inactiveCategory-item:hover {
  border: 3px solid white;
  border-radius: var(--br-9);
  color: var(--denimBlue);
}

.bundleItSubmitButton {
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.bundleItSubmitButton:hover {
  background: var(--safeGreen);
}

.cancelBundleButton {
  -webkit-transition: color, border 0.2s ease;
  transition: color, border 0.2s ease;
}

.cancelBundleButton:hover {
  color: var(--sunsetRed);
  border-color: var(--sunsetRed);
}

.activeSuggestedItem-border {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  border: 3px solid var(--safeGreen);
}

.activeSuggestedItem-color {
  color: var(--safeGreen);
}

.activeSuggestedItem-bg-color {
  background: var(--safeGreen);
}

.suggestions-checked-icon-size {
  font-size: 2rem;
}

.suggested-icon-size {
  font-size: 4rem;
}

.publicHomeBanner {
  background: -webkit-gradient(linear, left top, right top, from(var(--bundleBlue70)), to(var(--bundleBlue70))),
    url(/static/media/itemsCarousel.c69bf4f5.gif);
  background: -webkit-linear-gradient(left, var(--bundleBlue70), var(--bundleBlue70)),
    url(/static/media/itemsCarousel.c69bf4f5.gif);
  background: linear-gradient(90deg, var(--bundleBlue70), var(--bundleBlue70)),
    url(/static/media/itemsCarousel.c69bf4f5.gif);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.createTripForm-mb {
  margin-bottom: 20rem;
}

.publicHome-width {
  width: 120rem;
}

.publicHome-c-babyBlue {
  color: var(--babyBlue);
}

.loading-fullscreen {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4896c9;
  z-index: 1050;
}

.loading-InnerBlock {
  position: relative;
  width: 100%;
  height: 100%;
}

.loadingScreen-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 0.5fr;
  grid-template-areas:
    ". . ."
    ". loadBundle-img ."
    ". loadBundle-img ."
    ". loadBundle-text ."
    ". loadBundle-text .";
}

.loadBundle-img {
  grid-area: loadBundle-img;
  border-radius: var(--br-9), var(--br-9) 0 0;
  background-image: url(/static/media/bundle_load.ed2c230a.gif);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.loadBundle-img:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.loadBundle-text {
  margin-top: -7rem;
  grid-area: loadBundle-text;
  text-align: center;
  align-self: start;
}

.homeSavedImage-card {
  opacity: 0.9;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.homeSavedImage-card:hover {
  opacity: 1;
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
  z-index: 3;
}

.homeSavedImage-container {
  position: relative;
  width: 100%;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
}

.homeSavedImage {
  opacity: 1;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.homeMiddle {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.homeSavedImage-card:hover .homeSavedImage {
  opacity: 0.3;
}

.homeSavedImage-card:hover .homeMiddle {
  opacity: 1;
}

.savedHomeTrips-Text {
  text-decoration: none;
}

.savedHomeTrips-Text:hover {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .login-home-container {
    background: rgb(38, 66, 178);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(38, 66, 178, 1)),
      to(rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100vh;
    padding-top: 8rem;
    color: white;
  }

  .login-home-trips-header {
    font-size: 4.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .login-home-container {
    background: rgb(38, 66, 178);
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(38, 66, 178, 1)),
      to(rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100vh;
    padding-top: 8rem;
    color: white;
    width: 100% !important;
  }

  .login-home-trips-header {
    font-size: 4.5rem;
  }

  .lg-screen-space {
    height: 7rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (max-width: 600px) {
  .card-vertical-space {
    height: 8rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .card-vertical-space {
    height: 15rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
  }
}


.todo-font-sm {
  font-size: 1.2rem;
}

.todo-list-view {
  /* overflow-y: auto; */
  width: 100%;
  justify-content: center;
}

.tdl-view-font-lg {
  font-size: 1.8em;
}

.tdl-view-font-md {
  font-size: 1.3em;
}

.todo {
  /* margin: 4px; */
  margin: 0.4rem 0rem;
  box-shadow: 1px 1px 1px 0px #aaa;
  border-radius: 0.9rem;
  width: 100%;
}

.completed-container {
  border: 1px solid var(--bundleBlue);
}

.incomplete-header-button {
  background-color: var(--bundleBlue);
  border: 1px solid var(--bundleBlue);
  color: var(--white);
}

.incomplete-header-button:focus {
  outline: none;
}

.incomplete-header {
  border: 1px solid var(--bundleBlue);
}

.incomplete-header:hover {
  color: var(--white);
}

.incomplete-header:focus {
  outline: none;
}

.complete-header {
  border: 1px solid var(--smokeGrey);
}

.complete-header:focus {
  outline: none !important;
}

.add-todo-button {
  min-height: 4.5rem;
  min-width: 4.5rem;
  bottom: 0;
  color: var(--white);
  border: 1px solid var(--denimBlue);
}

.add-todo-button:hover {
  background: var(--white);
  color: var(--denimBlue);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.add-todo-button:focus {
  outline: none;
}

.dropdown-container {
  height: 8rem;
  width: 22rem;
  position: absolute;
  box-shadow: -1px 7px 14px #9d9d9d;
  top: -10px;
  right: -35px;
  left: 15px;
}

.border-denimBlue {
  border: 1px solid var(--denimBlue);
}

.add-todo-dropdown-row {
  border-radius: 0.8rem;
}

.add-todo-label {
  font-size: 1.3rem;
  font-weight: bolder;
  text-shadow: 0.01rem 0.01rem 0.01rem var(--smokeGrey);
}

.add-todo-input {
  width: 100%;
}

.add-button {
  border-radius: 14px;
}

.add-button:hover {
  background: var(--white);
  color: var(--denimBlue);
  border: 1px solid var(--denimBlue);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.reminderspage--lists {
  overflow-y: auto;
}

.button-container {
  position: fixed;
  bottom: 0;
  /* left: 40%; */
}

.uItem {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1.5px 3.33px 0 rgba(0, 0, 0, 0.19);
}

.uItem--head {
  background-color: rgba(255, 255, 255, 0.7);
  overflow: scroll;
  border-top-left-radius: var(--br-9);
  border-top-right-radius: var(--br-9);
}

.uItem--buttons {
  /* margin-top: 11rem; */
  margin-top: 79%;
}

.uItem--button-main {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
}

.uItem--button-left {
  background-color: #4996c9;
  border-left: 1px solid #4996c9;
  border-right: 1px solid #4996c9;
  border-top: 1px solid #4996c9;
  border-bottom: 1px solid #4996c9;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding-top: 0.5rem;
}

.uItem--button-right {
  background-color: white;
  border-left: 1px solid #4996c9;
  border-right: 1px solid #4996c9;
  border-top: 1px solid #4996c9;
  border-bottom: 1px solid #4996c9;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: var(--bundleBlue);
}

.uItem--button-right:hover {
  background-color: #39b225;
  border-left: 1px solid #39b225;
  border-right: 1px solid #39b225;
  border-top: 1px solid #39b225;
  border-bottom: 1px solid #39b225;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--white);
}

.uItem--button-rightDelete {
  background-color: white;
  border-left: 1px solid #4996c9;
  border-right: 1px solid #4996c9;
  border-top: 1px solid #4996c9;
  border-bottom: 1px solid #4996c9;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: var(--bundleBlue);
}

.uItem--button-rightDelete:hover {
  background-color: var(--sunsetRed);
  border-left: 1px solid var(--sunsetRed);
  border-right: 1px solid var(--sunsetRed);
  border-top: 1px solid var(--sunsetRed);
  border-bottom: 1px solid var(--sunsetRed);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--white);
}

.uItem--quantity-button {
  background-color: #4996c9;
  border: 1px solid #4996c9;
}

.uItem--quantity-weight {
  font-weight: 700;
}

.uItem--title--font {
  font-weight: 700;
  vertical-align: middle;
  width: 100%;
}

.uItem--content-main {
  background-size: cover;
  background-position: center;
}

/* Sizing  */

.uItem--unpacked {
  width: 140px;
  height: 180px;
}

.uItem--unpacked-md {
  width: 220px;
  height: 260px;
}

.uItem--unpacked-lg {
  width: 260px;
  height: 300px;
}

.uItem--unpacked-xlg {
  width: 300px;
  height: 340px;
}

.uItem--unpacked-xxlg {
  width: 340px;
  height: 380px;
}

.uItem--header {
  height: 2.5rem;
  font-size: 1.5rem;
}

.uItem--header-md {
  height: 2.8rem;
  font-size: 1.8rem;
}

.uItem--header-lg {
  height: 3.3rem;
  font-size: 2.1rem;
}

.uItem--header-xlg {
  height: 3.6rem;
  font-size: 2.3rem;
}

.uItem--header-xxlg {
  height: 4.2rem;
  font-size: 2.6rem;
}

.uItem--button {
  height: 30px;
  width: 100px;
}

.uItem--button-md {
  height: 40px;
  width: 100px;
}

.uItem--button-lg {
  height: 50px;
  width: 100px;
}

.uItem--button-xlg {
  height: 48px;
  width: 100px;
}

.uItem--button-xxlg {
  height: 55px;
  width: 100px;
}

.uItem--btnrow {
  font-size: 1.2rem;
}

.uItem--btnrow-md {
  margin-top: 0.2rem;
  font-size: 1.4rem;
}

.uItem--btnrow-lg {
  margin-top: 1rem;
  font-size: 1.4rem;
}

.uItem--btnrow-xlg {
  margin-top: 0.8rem;
  font-size: 1.4rem;
}

.uItem--btnrow-xxlg {
  margin-top: 0.8rem;
  font-size: 1.6rem;
}

.uItem--pack {
  font-size: 1.5rem;
}

.uItem--pack-md {
  font-size: 1.8rem;
}

.uItem--pack-lg {
  font-size: 2.1rem;
}

.uItem--pack-xlg {
  font-size: 2.3rem;
}

.uItem--pack-xxlg {
  font-size: 2.6rem;
}

.uItem--quantity {
  font-size: 1.4rem;
}

.uItem--quantity-md {
  font-size: 1.8rem;
}

.uItem--quantity-lg {
  margin-top: 0.5rem;
  font-size: 2rem;
}

.uItem--quantity-xlg {
  margin-top: 0.3rem;
  font-size: 2.2rem;
}

.uItem--quantity-xxlg {
  margin-top: 0.5rem;
  font-size: 2.2rem;
}

.pItem {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1.5px 3.33px 0 rgba(0, 0, 0, 0.19);
}

.pItem--head {
  background-color: rgba(255, 255, 255, 0.7);
  border-top-left-radius: var(--br-9);
  border-top-right-radius: var(--br-9);
  overflow: scroll;
}

.pItem--buttons {
  margin-top: 79%;
}

.pItem--button-main {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
}

.pItem--button-right {
  background-color: white;
  border: 1px solid #4996c9;
  border-radius: 1rem;
  color: var(--bundleBlue);
}

.pItem--button-right:hover {
  background-color: #39b225;
  border: 1px solid #39b225;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--white);
}

.pItem--button-rightDelete {
  background-color: white;
  border: 1px solid #4996c9;
  border-radius: 1rem;
  color: var(--bundleBlue);
}

.pItem--button-rightDelete:hover {
  background-color: var(--sunsetRed);
  border: 1px solid var(--sunsetRed);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--white);
}

.pItem--quantity-button {
  background-color: #4996c9;
  border: 1px solid #4996c9;
}

.pItem--quantity-weight {
  font-weight: 700;
}

.pItem--title--font {
  font-weight: 700;
  vertical-align: middle;
  width: 100%;
}

.pItem--content-main {
  background-size: cover;
  background-position: center;
}

/* Sizing  */

.pItem--unpacked {
  width: 140px;
  height: 180px;
}

.pItem--unpacked-md {
  width: 220px;
  height: 260px;
}

.pItem--unpacked-lg {
  width: 260px;
  height: 300px;
}

.pItem--unpacked-xlg {
  width: 300px;
  height: 340px;
}

.pItem--unpacked-xxlg {
  width: 340px;
  height: 380px;
}

.pItem--header {
  height: 2.5rem;
  font-size: 1.5rem;
}

.pItem--header-md {
  height: 2.8rem;
  font-size: 1.8rem;
}

.pItem--header-lg {
  height: 3.3rem;
  font-size: 2.1rem;
}

.pItem--header-xlg {
  height: 3.6rem;
  font-size: 2.3rem;
}

.pItem--header-xxlg {
  height: 4.2rem;
  font-size: 2.6rem;
}

.pItem--button {
  height: 30px;
  width: 100px;
}

.pItem--button-md {
  height: 40px;
  width: 100px;
}

.pItem--button-lg {
  height: 50px;
  width: 100px;
}

.pItem--button-xlg {
  height: 48px;
  width: 100px;
}

.pItem--button-xxlg {
  height: 55px;
  width: 100px;
}

.pItem--btnrow {
  font-size: 1.2rem;
}

.pItem--btnrow-md {
  margin-top: 0.2rem;
  font-size: 1.4rem;
}

.pItem--btnrow-lg {
  margin-top: 1rem;
  font-size: 1.4rem;
}

.pItem--btnrow-xlg {
  margin-top: 0.8rem;
  font-size: 1.4rem;
}

.pItem--btnrow-xxlg {
  margin-top: 0.8rem;
  font-size: 1.6rem;
}

.pItem--pack {
  font-size: 1.5rem;
}

.pItem--unpack-md {
  font-size: 1.8rem;
}

.pItem--unpack-lg {
  font-size: 2.1rem;
}

.pItem--unpack-xlg {
  font-size: 2.3rem;
}

.pItem--unpack-xxlg {
  font-size: 2.6rem;
}

.pItem--quantity {
  font-size: 1.4rem;
}

.pItem--quantity-md {
  font-size: 1.8rem;
}

.pItem--quantity-lg {
  margin-top: 0.5rem;
  font-size: 2rem;
}

.pItem--quantity-xlg {
  margin-top: 0.3rem;
  font-size: 2.2rem;
}

.pItem--quantity-xxlg {
  margin-top: 0.5rem;
  font-size: 2.2rem;
}

/* .bag--body-size {
  max-height: 450px;
  overflow-y: "scroll";
} */
.packed--header-color {
  background-color: #4896c9;
}

.bag--packed-button {
  background-color: var(--bundleBlue);
  border-top-left-radius: var(--br-9);
  border-top-right-radius: var(--br-9);
  border: 1px solid var(--bundleBlue);
  height: 5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  top: 0%;
}

.bag--packed-button:focus {
  outline: var(--bundleBlue);
}

.bag--packed-title {
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 600;
}

.bag--packed-count {
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 550;
}

.bag--packed-container {
  background-color: var(--bundleBlue);
  border-bottom-left-radius: var(--br-9);
  border-bottom-right-radius: var(--br-9);
}

/*    ---------     */

.bag--unpacked-button {
  background-color: var(--smokeGrey);
  border-top-left-radius: var(--br-9);
  border-top-right-radius: var(--br-9);
  border: 1px solid var(--smokeGrey);
  height: 5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  top: 0%;
}

.bag--unpacked-button:focus {
  outline: var(--smokeGrey);
}

.bag--button-bottom {
  border-bottom-left-radius: var(--br-9);
  border-bottom-right-radius: var(--br-9);
}

.bag--unpacked-title {
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 600;
}

.bag--unpacked-count {
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 550;
}

.bag--unpacked-container {
  background-color: var(--smokeGrey);
  border-bottom-left-radius: var(--br-9);
  border-bottom-right-radius: var(--br-9);
}

.mbutton--container {
  background: var(--denimBlue);
  border: 1px solid var(--denimBlue);
  min-height: 4.5rem;
  min-width: 4.5rem;
}

.mbutton--container:hover {
  background: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mbutton--container:focus {
  outline: var(--denimBlue);
}

.mbutton--icon {
  color: var(--white);
  font-weight: 700;
  font-size: 1.8rem;
}

.mbutton--icon:hover {
  color: var(--denimBlue);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mBar--modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.mBar--modal-content {
  height: 18rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 30.75rem;
  pointer-events: auto;
  background-color: var(--babyBlue);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: var(--br-9);
  outline: 0;
}

.mBar--modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-top: 1px solid var(--bundleBlue);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.mBar--item-add {
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  border: 1px solid var(--bundleBlue);
  background-color: var(--bundleBlue);
  height: 2.6rem;
  padding: 0.8rem;
}

.mBar--item-add:hover {
  background-color: var(--white);
  color: var(--bundleBlue);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mBar--item-cancel {
  color: white;
  font-weight: 700;
  border: 1px solid var(--smokeGrey);
  border-radius: 1rem;
  background-color: var(--smokeGrey);
  height: 3rem;
  padding: 0.8rem;
}

.mBar--item-cancel:hover {
  background-color: var(--smokeBlack);
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mBar--item-input {
  height: 100%;
  width: 5rem;
}

.mBar--item-input:focus {
  outline-color: var(--bundleBlue);
}

.mBar--modal-title {
  font-size: 1.5rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 700;
}

.mBar--item-label {
  font-size: 1.3rem;
}

.ppage--main {
  background-color: var(--babyBlue);
}

.ppage--bag {
  overflow-y: auto;
}

.ppage--menubar-position {
  bottom: 4.7em;
}

.pack--arrow-transform {
  -webkit-transform: scale(1.8, 1);
          transform: scale(1.8, 1);
}

.packingoverview--content-main {
  background-color: var(--bundleBlue);
  position: relative;
  overflow: hidden;
}

.packingoverview--content-main img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.packing--img-cover {
  object-fit: cover;
  /* background-position: center; */
}

.packingoverview--main-background {
  background-color: var(--babyBlue);
}

.cbag--inactive-color {
  background-color: #d3d3d3;
}

.cbag--active-color {
  background-color: #4896c9;
}

.cbag--active {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.3em;
  color: var(--bundleBlue);
  border: solid 0.3em var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cbag--inactive {
  width: 100%;
  height: 100%;
  font-size: 1em;
  border: solid 1px var(--smokeGrey30);
  color: var(--bundleBlue70);
  border-radius: var(--br-9);
  box-shadow: var(--smokeGrey30);
  background-color: rgba(255, 255, 255, 0.938);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cbag--inactive:hover {
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  font-weight: 700;
  color: var(--bundleBlue);
  /* border: solid .5rem v; */
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cbag--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.cbag--button:hover,
.cbag--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

/* sizing */

.cbag--size {
  height: 7rem;
  width: 6.8rem;
  font-size: 1rem;
}

.cbag--size-md {
  height: 7rem;
  width: 9em;
  font-size: 1.1rem;
}

.cbag--size-lg {
  height: 8rem;
  width: 11em;
  font-size: 1.2rem;
}

.cbag--size-xlg {
  height: 8rem;
  width: 13em;
  font-size: 1.3rem;
}

.cbag--size-xxlg {
  height: 9rem;
  width: 15em;
  font-size: 1.5rem;
}

.tabs--main {
  padding-top: 1.3rem;
  margin-bottom: 1rem;
}

.tabs--header {
  font-size: 2em;
}

.tabs-active {
  font-weight: 700;
  color: var(--white);
  border-bottom: 0.2rem solid white;
  padding-bottom: 0.4rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabs-inactive {
  color: rgba(251, 251, 251, 0.8);
}

.tabs-inactive:hover {
  border-bottom: 0.2rem solid rgb(179, 176, 176);
  padding-bottom: 0.4rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabs--trip {
  font-size: 1.5em;
  color: var(--white);
}
.tabs--details {
  font-size: 1.5em;
  color: var(--white);
}

.tabs--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.tabs--button:hover,
.tabs--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

.progress--style {
  /* color: white; */
  width: 100px;
  /* text-shadow: 2px 2px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.cList--inactive-color {
  background-color: #d3d3d3;
}

.cList--active-color {
  background-color: #4896c9;
}
.cList--active {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--inactive {
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  border: solid 1px var(--smokeGrey30);
  color: var(--bundleBlue70);
  border-radius: var(--br-9);
  box-shadow: var(--smokeGrey30);
  background-color: rgba(255, 255, 255, 0.938);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--inactive:hover {
  width: 100%;
  height: 100%;
  font-weight: 700;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.cList--button:hover,
.cList--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

/* sizing */

.cList--size {
  height: 7rem;
  width: 6.8rem;
  font-size: 1rem;
}

.cList--size-md {
  height: 7rem;
  width: 8.2rem;
  font-size: 1.1rem;
}

.cList--size-lg {
  height: 8rem;
  width: 10rem;
  font-size: 1.2rem;
}

.cList--size-xlg {
  height: 8rem;
  width: 12rem;
  font-size: 1.3rem;
}

.cList--size-xxlg {
  height: 9rem;
  width: 13rem;
  font-size: 1.5rem;
}

.cList--inactive-color {
  background-color: #d3d3d3;
}

.cList--active-color {
  background-color: #4896c9;
}
.cList--active {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--inactive {
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  border: solid 1px var(--smokeGrey30);
  color: var(--bundleBlue70);
  border-radius: var(--br-9);
  box-shadow: var(--smokeGrey30);
  background-color: rgba(255, 255, 255, 0.938);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--inactive:hover {
  width: 100%;
  height: 100%;
  font-weight: 700;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cList--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.cList--button:hover,
.cList--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

/* sizing */

.cList--size {
  height: 7rem;
  width: 6.8rem;
  font-size: 1rem;
}

.cList--size-md {
  height: 7rem;
  width: 8.2rem;
  font-size: 1.1rem;
}

.cList--size-lg {
  height: 8rem;
  width: 10rem;
  font-size: 1.2rem;
}

.cList--size-xlg {
  height: 8rem;
  width: 12rem;
  font-size: 1.3rem;
}

.cList--size-xxlg {
  height: 9rem;
  width: 13rem;
  font-size: 1.5rem;
}

.addlist--modal-button {
  display: inline-block;
  font-weight: 400;
  color: var(--bundleBlue);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: #17a2b8 !important;
  border: 1px solid var(--bundleBlue);
}

.addlist--modal-button:hover {
  border: 1px solid var(--denimBlue);
  background-color: var(--denimBlue);
  color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.addlist--modal-close {
  font-size: 2rem;
  color: var(--denimBlue);
}

.fancy-progress-bar {
  background: var(--bundleBlue); /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    var(--bundleBlue) 0%,
    var(--denimBlue) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: -webkit-linear-gradient(
    315deg,
    var(--bundleBlue) 0%,
    var(--denimBlue) 100%
  );
  background: linear-gradient(
    135deg,
    var(--bundleBlue) 0%,
    var(--denimBlue) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.toastDropShadow {
  box-shadow: 5px 5px 20px 0px var(--bundleBlue);
}

.toast-text-color {
  color: var(--denimBlue);
}

.packHacks-cardContainer {
  overflow-x: scroll;
  display: flex;
  flex: 3 1;
  align-items: center;
}

.packHacks-cardContainer::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* NOTE: the order of these styles DO matter */

.DateInput_input {
  font-weight: 400;
  color: var(--smokeblack);
}

.DateRangePickerInput_calendarIcon_svg {
  fill: var(--smokeblack);
}

.DateRangePickerInput_arrow_svg {
  fill: var(--smokeBlack);
}

.DateInput_input__focused {
  border-bottom: 2px solid var(--bundleBlue);
}

/*  Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: var(--bundleBlue); /* background */
  color: var(--huate); /* text */
  border: 1px solid var(--huate); /* default styles include a border */
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.CalendarDay__selected_span:hover {
  background: var(--bundleBlue70);
  border: var(--bundleBlue70);
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: var(--denimBlue);
  border: var(--huate);
  color: var(--huate);
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: var(--denimBlue);
  border: var(--denimBlue);
  color: var(--huate);
}

.CalendarDay__selected:active {
  background: var(--denimBlue);
  border: var(--denimBlue);
  color: var(--huate);
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--bundleBlue);
  color: var(--huate);
  border: var(--huate);
}

/* Imports google fonts directly into the CSS */

/* 
font-family: "Muli", sans-serif; 
font-family: 'Baloo', cursive;
*/

/* Removes any DEFAULT padding or margin from all elements */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Adds the box sizing to every element */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* includes the padding in the dimensions of the boxes */
html {
  box-sizing: border-box;
  font-size: 62.5%; /* 10/16 =.625 10px*/
}

body {
  font-family: "Muli", sans-serif;
}

:root {
  --white: #ffffff;
  --white80: rgba(251, 251, 251, 0.8);
  --huate: #eef0f2;
  --huate80: rgba(238, 240, 242, 0.8);
  --babyBlue: #c3d8e7;
  --babyBlue50: rgba(73, 150, 201, 0.5);
  --bundleBlue: #4996c9;
  --bundleBlue70: rgba(73, 150, 201, 0.7);
  --denimBlue: #2541b2;
  --smokeBlack: #141414;
  --smokeGrey: #9d9d9d;
  --smokeGrey30: rgba(157, 157, 157, 0.3);
  --safeGreen: #39b225;
  --sunsetRed: #d9534f;
  --ff-baloo: "Baloo", cursive;
  --ff-mali: "Muli", sans-serif;
  --ds-lightGrey: 0px 4px 7px rgba(0, 0, 0, 0.25);
  --br-9: 0.9rem;
  --br-18: 1.8rem;
  --bundleBlue-border-3: 0.3rem solid var(--bundleBlue);
  --bundleBlue-border-2: 0.2rem solid var(--bundleBlue);
  --bundleBlue-border-1: 0.1rem solid var(--bundleBlue);
}

.mali300 {
  font-family: "Muli", sans-serif;
  font-family: var(--ff-mali);
  font-weight: 300;
}

.mali400 {
  font-family: "Muli", sans-serif;
  font-family: var(--ff-mali);
  font-weight: 400;
}

.mali700 {
  font-family: "Muli", sans-serif;
  font-family: var(--ff-mali);
  font-weight: 700;
}

.mali900 {
  font-family: "Muli", sans-serif;
  font-family: var(--ff-mali);
  font-weight: 900;
}

.baloo {
  font-family: "Baloo", cursive;
  font-family: var(--ff-baloo);
}

.ds-lightGrey {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  box-shadow: var(--ds-lightGrey);
}

.b-radius9 {
  border-radius: 0.9rem;
  border-radius: var(--br-9);
}

.b-radius18 {
  border-radius: 1.8rem;
  border-radius: var(--br-18);
}

.bg-white {
  background: #ffffff;
  background: var(--white);
}

.bg-white80 {
  background: rgba(251, 251, 251, 0.8);
  background: var(--white80);
}

.bg-huate80 {
  background: rgba(238, 240, 242, 0.8);
  background: var(--huate80);
}

.bg-huate {
  background: #eef0f2;
  background: var(--huate);
}

.bg-babyBlue {
  background: #c3d8e7;
  background: var(--babyBlue);
}

.bg-babyBlue50 {
  background: rgba(73, 150, 201, 0.5);
  background: var(--babyBlue50);
}

.bg-opacity80 {
  background: var(--opacity80);
}

.bg-bundleBlue70 {
  background: rgba(73, 150, 201, 0.7);
  background: var(--bundleBlue70);
}

.bg-bundleBlue {
  background: #4996c9;
  background: var(--bundleBlue);
}

.bg-denimBlue {
  background: #2541b2;
  background: var(--denimBlue);
}

.bg-smokeGrey {
  background: #9d9d9d;
  background: var(--smokeGrey);
}

.bg-bundleBlueBabyBlue {
  background: -webkit-gradient(linear, left top, left bottom, from(#4996c9), to(#c3d8e7));
  background: -webkit-linear-gradient(top, #4996c9, #c3d8e7);
  background: linear-gradient(180deg, #4996c9, #c3d8e7);
  background: -webkit-gradient(linear, left top, left bottom, from(var(--bundleBlue)), to(var(--babyBlue)));
  background: -webkit-linear-gradient(top, var(--bundleBlue), var(--babyBlue));
  background: linear-gradient(180deg, var(--bundleBlue), var(--babyBlue));
}

.bg-denimBlueBabyBlue {
  background: -webkit-gradient(linear, left top, left bottom, from(#2541b2), to(#c3d8e7));
  background: -webkit-linear-gradient(top, #2541b2, #c3d8e7);
  background: linear-gradient(180deg, #2541b2, #c3d8e7);
  background: -webkit-gradient(linear, left top, left bottom, from(var(--denimBlue)), to(var(--babyBlue)));
  background: -webkit-linear-gradient(top, var(--denimBlue), var(--babyBlue));
  background: linear-gradient(180deg, var(--denimBlue), var(--babyBlue));
}

.c-bundleBlue {
  color: #4996c9;
  color: var(--bundleBlue);
}

.c-huate {
  color: #eef0f2;
  color: var(--huate);
}

.c-white {
  color: #ffffff;
  color: var(--white);
}

.c-smokeGrey {
  color: #9d9d9d;
  color: var(--smokeGrey);
}

.c-denimBlue {
  color: #2541b2;
  color: var(--denimBlue);
}

.bundeBlue-border-3 {
  border: 0.3rem solid #4996c9;
  border: var(--bundleBlue-border-3);
}

.bundleBlue-border-bottom-3 {
  border-bottom: 0.3rem solid #4996c9;
  border-bottom: var(--bundleBlue-border-3);
}

.bundleBlue-border-left-3 {
  border-left: 0.3rem solid #4996c9;
  border-left: var(--bundleBlue-border-3);
}

.bundleBlue-border-right-3 {
  border-right: 0.3rem solid #4996c9;
  border-right: var(--bundleBlue-border-3);
}

.bundeBlue-border-2 {
  border: 0.2rem solid #4996c9;
  border: var(--bundleBlue-border-2);
}

.bundleBlue-border-bottom-2 {
  border-bottom: 0.2rem solid #4996c9;
  border-bottom: var(--bundleBlue-border-2);
}

.bundleBlue-border-left-2 {
  border-left: 0.2rem solid #4996c9;
  border-left: var(--bundleBlue-border-2);
}

.bundleBlue-border-right-2 {
  border-right: 0.2rem solid #4996c9;
  border-right: var(--bundleBlue-border-2);
}

.bundeBlue-border-1 {
  border: 0.1rem solid #4996c9;
  border: var(--bundleBlue-border-1);
}

.bundleBlue-border-bottom-1 {
  border-bottom: 0.1rem solid #4996c9;
  border-bottom: var(--bundleBlue-border-1);
}

.bundleBlue-border-left-1 {
  border-left: 0.1rem solid #4996c9;
  border-left: var(--bundleBlue-border-1);
}

.bundleBlue-border-right-1 {
  border-right: 0.1rem solid #4996c9;
  border-right: var(--bundleBlue-border-1);
}

.bundleBlueButton {
  background: #4996c9;
  background: var(--bundleBlue);
  border-radius: 0.9rem;
  border-radius: var(--br-9);
  color: #ffffff;
  color: var(--white);
}

.capitalizeText {
  text-transform: capitalize;
}

