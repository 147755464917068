@media only screen and (max-width: 600px) {
  .add-itinerary-form-container {
    margin-top: 2.5rem;
    margin-left: 0.25rem;
    padding: 2rem;
    width: 100%;
  }

  .add-itinerary-form-label {
    font-size: 1.5rem;
  }

  .add-itinerary-form-input {
    font-size: 1.5rem;
  }

  .add-itinerary-form-type-selector {
    border: 1px solid grey;
    padding: 0.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .add-itinerary-form-container {
    margin-top: 2.5rem;
    padding: 2rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .add-itinerary-form-label {
    font-size: 1.5rem;
  }

  .add-itinerary-form-input {
    font-size: 1.5rem;
  }

  .add-itinerary-form-type-selector {
    border: 1px solid grey;
    padding: 0.5rem;
    font-size: 2.5rem !important;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}
