@media only screen and (max-width: 600px) {
  .login-home-container {
    background: rgb(38, 66, 178);
    background: linear-gradient(
      180deg,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100vh;
    padding-top: 8rem;
    color: white;
  }

  .login-home-trips-header {
    font-size: 4.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .login-home-container {
    background: rgb(38, 66, 178);
    background: linear-gradient(
      180deg,
      rgba(38, 66, 178, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100vh;
    padding-top: 8rem;
    color: white;
    width: 100% !important;
  }

  .login-home-trips-header {
    font-size: 4.5rem;
  }

  .lg-screen-space {
    height: 7rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }
}
