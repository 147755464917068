.createTripform-button-disabled {
  cursor: not-allowed;
}

.createTripform-button {
  transition: background 0.2s ease;
}

.createTripform-button:hover {
  background: #1e3799;
}

.createTrip-icon {
  font-size: 1.5rem;
  color: var(--smokeblack);
  padding-left: 1.5rem;
}

.createTripform-input {
  font-size: 1.9rem;
  outline-color: var(--bundleBlue);
  color: var(--smokeblack);
  border-radius: 0;
}

.createTripform-input::placeholder {
  color: var(--smokeblack);
  opacity: 1; /* Firefox */
}

.createTripform-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--smokeblack);
}

.createTripform-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--smokeblack);
}

.createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover,
.createTripform-input:-webkit-autofill:focus
  .createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover
  .createTripform-input:-webkit-autofill:focus,
.createTripform-input:-webkit-autofill,
.createTripform-input:-webkit-autofill:hover,
.createTripform-input:-webkit-autofill:focus {
  -webkit-box-shadow: transparent;
  -webkit-text-fill-color: var(--smokeblack);
  transition: background-color 5000s ease-in-out 0s;
}

.ap-input {
  height: 45px;
}

.ais-InstantSearch__root {
  width: 100%;
}

.placeholder-destination::placeholder {
  color: grey;
}
