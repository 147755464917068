@media only screen and (max-width: 600px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 100%;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
  }
}

@media only screen and (max-width: 768px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 100%;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
  }
}

@media only screen and (min-width: 768px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-title {
    font-size: 3.25rem;
  }

  .itinerary-container {
    padding: 1rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
    font-size: 1.5rem !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .itinerary-card-container {
    margin-top: 2.5rem;
    width: 30%;
    height: 350px;
    margin-right: 2rem;
  }

  .card-header-title {
    margin-left: 3.5rem;
    text-transform: capitalize;
    font-size: 2.5rem;
  }

  .global-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
  }

  .itinerary-title {
    font-size: 3.25rem;
  }

  .itinerary-container {
    padding: 1rem;
  }

  .itinerary-add-btn {
    color: white !important;
    background-color: #2541b2 !important;
    border-color: #2541b2 !important;
    font-size: 1.5rem !important;
  }

  .itinerary-cancel-btn {
    color: white !important;
    background-color: #d9534f !important;
    border-color: #d9534f !important;
    font-size: 1.5rem !important;
  }
}
