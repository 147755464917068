.displayNone {
  display: none;
}

.displayModal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.714);
  z-index: 1000;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

.displayModalMain {
  position: fixed;
  background: #e6e6e6;
  width: 95%;
  height: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalCloseButton {
  position: absolute;
  top: 0;
  right: 30px;
  margin: 10px;
  background: none;
  border: none;
  font-size: 50px;
}

.modalContent {
  margin: 20px;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipOutY {
  animation-duration: 0.75s;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}
