.cList--inactive-color {
  background-color: #d3d3d3;
}

.cList--active-color {
  background-color: #4896c9;
}
.cList--active {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  transition: 0.3s;
}

.cList--inactive {
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  border: solid 1px var(--smokeGrey30);
  color: var(--bundleBlue70);
  border-radius: var(--br-9);
  box-shadow: var(--smokeGrey30);
  background-color: rgba(255, 255, 255, 0.938);
  transition: 0.3s;
}

.cList--inactive:hover {
  width: 100%;
  height: 100%;
  font-weight: 700;
  color: var(--bundleBlue);
  border: solid 1px var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  transition: 0.3s;
}

.cList--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.cList--button:hover,
.cList--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

/* sizing */

.cList--size {
  height: 7rem;
  width: 6.8rem;
  font-size: 1rem;
}

.cList--size-md {
  height: 7rem;
  width: 8.2rem;
  font-size: 1.1rem;
}

.cList--size-lg {
  height: 8rem;
  width: 10rem;
  font-size: 1.2rem;
}

.cList--size-xlg {
  height: 8rem;
  width: 12rem;
  font-size: 1.3rem;
}

.cList--size-xxlg {
  height: 9rem;
  width: 13rem;
  font-size: 1.5rem;
}

.addlist--modal-button {
  display: inline-block;
  font-weight: 400;
  color: var(--bundleBlue);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: #17a2b8 !important;
  border: 1px solid var(--bundleBlue);
}

.addlist--modal-button:hover {
  border: 1px solid var(--denimBlue);
  background-color: var(--denimBlue);
  color: var(--white);
  transition: 0.3s;
}

.addlist--modal-close {
  font-size: 2rem;
  color: var(--denimBlue);
}
