.pack--arrow-transform {
  transform: scale(1.8, 1);
}

.packingoverview--content-main {
  background-color: var(--bundleBlue);
  position: relative;
  overflow: hidden;
}

.packingoverview--content-main img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.packing--img-cover {
  object-fit: cover;
  /* background-position: center; */
}

.packingoverview--main-background {
  background-color: var(--babyBlue);
}
