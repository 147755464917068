.homeSavedImage-card {
  opacity: 0.9;
  transition: transform 0.5s ease;
}

.homeSavedImage-card:hover {
  opacity: 1;
  transform: scale(1.07);
  z-index: 3;
}

.homeSavedImage-container {
  position: relative;
  width: 100%;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
}

.homeSavedImage {
  opacity: 1;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.homeMiddle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.homeSavedImage-card:hover .homeSavedImage {
  opacity: 0.3;
}

.homeSavedImage-card:hover .homeMiddle {
  opacity: 1;
}

.savedHomeTrips-Text {
  text-decoration: none;
}

.savedHomeTrips-Text:hover {
  text-decoration: none;
}
