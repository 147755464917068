.cbag--inactive-color {
  background-color: #d3d3d3;
}

.cbag--active-color {
  background-color: #4896c9;
}

.cbag--active {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.3em;
  color: var(--bundleBlue);
  border: solid 0.3em var(--bundleBlue);
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  transition: 0.3s;
}

.cbag--inactive {
  width: 100%;
  height: 100%;
  font-size: 1em;
  border: solid 1px var(--smokeGrey30);
  color: var(--bundleBlue70);
  border-radius: var(--br-9);
  box-shadow: var(--smokeGrey30);
  background-color: rgba(255, 255, 255, 0.938);
  transition: 0.3s;
}

.cbag--inactive:hover {
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  font-weight: 700;
  color: var(--bundleBlue);
  /* border: solid .5rem v; */
  border-radius: var(--br-9);
  box-shadow: var(--ds-lightGrey);
  background-color: var(--white);
  transition: 0.3s;
}

.cbag--button {
  background: none;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

.cbag--button:hover,
.cbag--button:focus {
  font-weight: 700;
  outline: var(--bundleBlue);
}

/* sizing */

.cbag--size {
  height: 7rem;
  width: 6.8rem;
  font-size: 1rem;
}

.cbag--size-md {
  height: 7rem;
  width: 9em;
  font-size: 1.1rem;
}

.cbag--size-lg {
  height: 8rem;
  width: 11em;
  font-size: 1.2rem;
}

.cbag--size-xlg {
  height: 8rem;
  width: 13em;
  font-size: 1.3rem;
}

.cbag--size-xxlg {
  height: 9rem;
  width: 15em;
  font-size: 1.5rem;
}
